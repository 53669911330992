<template>
	<main class="main-page">
		<template v-if="showHeader">
			<section class="page-section mb-3">
				<div class="container">
					<div class="grid align-items-center">
						<div v-if="!isSubPage" class="col-fixed ">
							<Button @click="$router.go(-1)" label="" className="p-button p-button-text "
								icon="pi pi-arrow-left" />
						</div>
						<div class="col ">
							<div class="">
								<div class="flex align-items-center ">
									<div class="mr-3">
										<Avatar size="large" class="" icon="pi pi-reply" />
									</div>
									<div>
										<div class="text-2xl text-primary font-bold">Allocate Room</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</template>
		<section class="page-section ">
			<div class="container">
				<div class="grid ">
					<div class="md:col-9 sm:col-12 comp-grid">
						<div>
							<template v-if="pageReady">
								<form ref="observer" tag="form" @submit.prevent="submitForm()"
									:class="{ 'card': !isSubPage }" class="">
									<!--[form-content-start]-->
									<div class="grid">
										<div class="col-12 md:col-6">
											<div class="mb-2 font-bold">Booking </div>
											<div id="ctrl-booking-holder">
												<InputText ref="ctrlbooking" v-model.trim="formData.booking" label="Booking"
													type="text" placeholder="Enter Booking" disabled class=" w-full"
													:class="getErrorClass('booking')">
												</InputText>
												<small v-if="isFieldValid('booking')" class="p-error">{{
													getFieldError('booking') }}</small>
											</div>
										</div>
										<div class="col-12 md:col-6">
											<div class="mb-2 font-bold"> Booking Status *</div>
											<div id="ctrl-booking_status-holder">
												<InputText ref="ctrlbooking_status" v-model.trim="formData.booking_status"
													label="Booking Status" type="text" placeholder="Enter Booking Status"
													disabled class=" w-full" :class="getErrorClass('booking_status')">
												</InputText>
												<small v-if="isFieldValid('booking_status')" class="p-error">{{
													getFieldError('booking_status') }}</small>
											</div>
										</div>
										<div class="col-12 md:col-6">
											<div class="mb-2 font-bold"> Room Allocation *</div>
											<div id="ctrl-room_allocation-holder">
												<api-data-source api-path="components_data/room_allocation_option_list">
													<template v-slot="req">
														<Dropdown class="w-full" :class="getErrorClass('room_allocation')"
															:loading="req.loading" optionLabel="label" optionValue="value"
															ref="ctrlroom_allocation" v-model="formData.room_allocation"
															:options="req.response" label="Room Allocation"
															placeholder="Select room ...">
															<template #option="slotProps">
																<div class="flex align-items-center">
																	<div class="ml-2" v-if="slotProps.option.image">
																		<Avatar
																			:image="$utils.setImgUrl(slotProps.option.image)" />
																	</div>
																	<div>
																		<div>{{ $utils.upper(slotProps.option.label) }}
																		</div>
																		<div v-if="slotProps.option.caption"
																			class="text-sm text-500">Beds Left: {{
																				slotProps.option.caption }}</div>
																	</div>
																</div>
															</template>
														</Dropdown>
														<small v-if="isFieldValid('room_allocation')" class="p-error">{{
															getFieldError('room_allocation') }}</small>
													</template>
												</api-data-source>
											</div>
										</div>
										<div class="col-12 md:col-6">
											<div class="mb-2 font-bold"> Room Number *</div>
											<div id="ctrl-room_number-holder">
												<InputText ref="ctrlroom_number" v-model.trim="formData.room_number"
													label="Room Number" type="text" placeholder="Enter Room Number"
													class=" w-full" :class="getErrorClass('room_number')">
												</InputText>
												<small v-if="isFieldValid('room_number')" class="p-error">{{
													getFieldError('room_number') }}</small>
											</div>
										</div>
									</div>
									<!--[form-content-end]-->
									<div v-if="showSubmitButton" class="text-center mt-3">
										<Button type="submit" label="Submit" icon="pi pi-send" :loading="saving" />
									</div>
								</form>
							</template>
							<template v-if="loading">
								<div class="p-3 text-center">
									<ProgressSpinner style="width:50px;height:50px" />
								</div>
							</template>
						</div>
					</div>
				</div>
			</div>
		</section>
	</main>
</template>
<script setup>
import { computed, reactive, toRefs, onMounted } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required } from '@/services/validators';
import { utils } from '@/utils';
import { useApp } from '@/composables/app.js';
import { useEditPage } from '@/composables/editpage.js';
const props = defineProps({
	id: [String, Number],
	pageName: {
		type: String,
		default: 'users',
	},
	routeName: {
		type: String,
		default: 'usersallocateroom',
	},
	pagePath: {
		type: String,
		default: 'users/allocateroom',
	},
	apiPath: {
		type: String,
		default: 'users/allocateroom',
	},
	submitButtonLabel: {
		type: String,
		default: "Submit",
	},
	formValidationError: {
		type: String,
		default: "Form is invalid",
	},
	formValidationMsg: {
		type: String,
		default: "Please complete the form",
	},
	msgTitle: {
		type: String,
		default: 'Update Record',
	},
	msgBeforeSave: {
		type: String,
		default: "",
	},
	msgAfterSave: {
		type: String,
		default: "Record updated successfully",
	},
	showHeader: {
		type: Boolean,
		default: true,
	},
	showSubmitButton: {
		type: Boolean,
		default: true,
	},
	redirect: {
		type: Boolean,
		default: true,
	},
	isSubPage: {
		type: Boolean,
		default: false,
	},
});
const app = useApp();
const formDefaultValues = Object.assign({
	booking: "",
	booking_status: "Allocated",
	room_allocation: "",
	room_number: "",
}, props.pageData);
const formData = reactive({ ...formDefaultValues });
function afterSubmit(response) {
	app.flashMsg(props.msgTitle, props.msgAfterSave);
	if (app.isDialogOpen()) {
		app.closeDialogs(); // if page is open as dialog, close dialog
	}
	else if (props.redirect) {
		app.navigateTo(`/`);
	}
}
const rules = computed(() => {
	return {
		booking: {},
		booking_status: { required },
		room_allocation: { required },
		room_number: { required }
	}
});
const v$ = useVuelidate(rules, formData); //form validation
const page = useEditPage({ props, formData, v$, afterSubmit });
const { submitted, saving, loading, pageReady } = toRefs(page.state);
const { apiUrl, currentRecord } = page.computedProps;
const { load, submitForm, getErrorClass, getFieldError, isFieldValid, mapOptionField } = page.methods;
onMounted(() => {
	const pageTitle = "Allocate Room";
	app.setPageTitle(props.routeName, pageTitle); // set browser page title
	load();
});
</script>
<style scoped></style>
